export const countriesData = [
  { value: "US", label: "United States of America" },
  { value: "UK", label: "United Kingdom" },
  { value: "GB", label: "United Kingdom of Great Britain and Northern Ireland" },
  { value: "CA", label: "Canada" },
  { value: "JE", label: "Jersey" },
  { value: "GG", label: "Guernsey" },
  { value: "IM", label: "Isle of Man" },
  { value: "IE", label: "Ireland" },
  { value: "DE", label: "Germany" },
  { value: "JP", label: "Japan" },
  { value: "FR", label: "France" },
  { value: "AU", label: "Australia" },
  { value: "IT", label: "Italy" },
  { value: "CH", label: "Switzerland" },
  { value: "AT", label: "Austria" },
  { value: "ES", label: "Spain" },
  { value: "NL", label: "Kingdom of the Netherlands" },
  { value: "BE", label: "Belgium" },
  { value: "DK", label: "Denmark" },
  { value: "SE", label: "Sweden" },
  { value: "NO", label: "Norway" },
  { value: "BR", label: "Brazil" },
  { value: "PT", label: "Portugal" },
  { value: "FI", label: "Finland" },
  { value: "AX", label: "Åland Islands" },
  { value: "KR", label: "Republic of Korea" },
  { value: "CN", label: "China" },
  { value: "TW", label: "Taiwan" },
  { value: "SG", label: "Singapore" },
  { value: "DZ", label: "Algeria" },
  { value: "AD", label: "Andorra" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BM", label: "Bermuda" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "KH", label: "Cambodia" },
  { value: "CV", label: "Cabo Verde" },
  { value: "CL", label: "Chile" },
  { value: "CR", label: "Costa Rica" },
  { value: "HR", label: "Croatia" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czechia" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "EE", label: "Estonia" },
  { value: "FO", label: "Faroe Islands" },
  { value: "GE", label: "Georgia" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GT", label: "Guatemala" },
  { value: "HT", label: "Haiti" },
  { value: "HN", label: "Honduras" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IL", label: "Israel" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KW", label: "Kuwait" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MK", label: "North Macedonia" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "MT", label: "Malta" },
  { value: "MU", label: "Mauritius" },
  { value: "MX", label: "Mexico" },
  { value: "MD", label: "Republic of Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MA", label: "Morocco" },
  { value: "NP", label: "Nepal" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NG", label: "Nigeria" },
  { value: "OM", label: "Oman" },
  { value: "PA", label: "Panama" },
  { value: "PK", label: "Pakistan" },
  { value: "PY", label: "Paraguay" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Poland" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "SM", label: "San Marino" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "ZA", label: "South Africa" },
  { value: "LK", label: "Sri Lanka" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TH", label: "Thailand" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Türkiye" },
  { value: "TM", label: "Turkmenistan" },
  { value: "UA", label: "Ukraine" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VA", label: "Holy See" },
  { value: "VE", label: "Bolivarian Republic of Venezuela" },
  { value: "ZM", label: "Zambia" },
  { value: "AS", label: "American Samoa" },
  { value: "CC", label: "Territory of Cocos (Keeling) Islands" },
  { value: "CK", label: "Cook Islands" },
  { value: "RS", label: "Serbia" },
  { value: "ME", label: "Montenegro" },
  // { value: "CS", label: "" },
  // { value: "YU", label: "" },
  { value: "CX", label: "Christmas Island" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "NF", label: "Norfolk Island" },
  { value: "FM", label: "Federated States of Micronesia" },
  { value: "GF", label: "French Guiana" },
  { value: "GN", label: "Guinea" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "GU", label: "Guam" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "HM", label: "Heard Island and McDonald Islands" },
  { value: "IQ", label: "Iraq" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LR", label: "Liberia" },
  { value: "LS", label: "Lesotho" },
  { value: "MG", label: "Madagascar" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MN", label: "Mongolia" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "NC", label: "New Caledonia" },
  { value: "NE", label: "Niger" },
  { value: "VI", label: "Virgin Islands (U.S.)" },
  { value: "VN", label: "Viet Nam" },
  { value: "PF", label: "French Polynesia" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PM", label: "Saint Pierre and Miquelon" },
  { value: "PN", label: "Pitcairn" },
  { value: "PW", label: "Palau" },
  { value: "RE", label: "Réunion" },
  { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "SJ", label: "Svalbard and Jan Mayen" },
  { value: "SO", label: "Somalia" },
  { value: "SZ", label: "Eswatini" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "XK", label: "Kosovo" },
  { value: "YT", label: "Mayotte" },
  { value: "INTL", label: "Other" },
];