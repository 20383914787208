import * as React from "react"
import RegisterContent from "../components/register"
import Seo from "../components/seo"
import Layout from "../components/app-layout"
import GlobalStyle from "../helpers/global-styles"
import Footer from "../components/footer"

const Register = () => (<Layout pageName={'Get in Touch'}>
  <GlobalStyle />
  <RegisterContent />
  <Footer />
</Layout>
)

export const Head = () => (
  <>
    {" "}
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1"
    />
    <Seo title="Register" />
  </>
)

export default Register
