export const RegisterDropdownFirst = [
  { value: "Google Search", label: "Google Search" },
  { value: "Streeteasy.com", label: "Streeteasy.com" },
  { value: "Broker", label: "Broker" },
  { value: "In the news", label: "In the news" },
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "Friend", label: "Friend" },
  { value: "Elliman.com", label: "Elliman.com" },
]
