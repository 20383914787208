export default function submitForm(data) {
  return fetch("/submit-form", {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then(() => {
      if (window.dataLayer) {
        window.dataLayer.push({ 'event': 'form_submit' })
      }
    })
    .catch(() => false)
}
