import React from "react"
import styled from "styled-components"
import { mediaMin, mediaMax } from "../../helpers/media-queries"
import Select from "react-dropdown-select"
import { calculateResponsiveSize } from "../../helpers/calculate-responsive-size"

export const RegisterWrap = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  min-height: calc(100vh - 340px);
  margin-top: 100px;
  ${mediaMin("desktopXLarge")} {
    padding-bottom: ${calculateResponsiveSize(140)};
    padding-top: ${calculateResponsiveSize(140)};
    margin: 0 auto;
  }
  ${mediaMax("tabletLandscape")} {
    padding-bottom: 0;
    justify-content: center;
    padding-top: ${calculateResponsiveSize(140)};
    width: 100%;
    flex-direction: column;
    margin: 70px auto 0;
  }
`

export const InputsWraper = styled.div`
  display: flex;
  width: 60%;
  gap: 5vw;
  ${mediaMax("tabletLandscape")} {
    flex-direction: column;
    width: 100%;
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  :first-child {
    & .react-dropdown-select {
      margin-bottom: 20px;
      .react-dropdown-select-input {
        margin-left: 0;
        &::placeholder {
          color: #47341E;
        }
      }
      ${mediaMin("desktopXLarge")} {
        margin-bottom: ${calculateResponsiveSize(20)};
      }
    }
  }

  ${mediaMax("tabletLandscape")} {
    button {
      margin-top: 40px;
    }
  }
  ${mediaMax("desktopSm")} {
  }
  .submit-btn {
    margin-top: 44px;
    ${mediaMax("tabletLandscape")} {
      margin-top: 0;
    }
    ${mediaMax("phoneXL")} {
      margin-left: auto;
    }
    ${mediaMin("desktopXLarge")} {
      margin-top: ${calculateResponsiveSize(44)};
    }
  }
`

export const CheckBoxGroupWrapper = styled.div`
 margin-top: 30px;
`

export const CheckboxLayoutWrapper = styled.div`
  padding: 12px 0px;
${mediaMax("tabletLandscape")} {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
`

export const FieldWrappper = styled.div`
  margin-bottom: 20px;
  max-width: 400px;
  width: 100%;
  border: 1px solid var(--main-color);
  height: 34px;
  box-sizing: border-box;
  &.is-invalid {
    border-color: #ff6b6b;
  }
  ${mediaMax("tabletLandscape")} {
    max-width: 100%;
  }
  ${mediaMax("phoneXL")} {
    max-width: 100%;
  }
  ${mediaMin("desktopXLarge")} {
    margin-bottom: ${calculateResponsiveSize(20)};
    max-width: ${calculateResponsiveSize(290)};
    height: ${calculateResponsiveSize(34)};
  }
`
export const Input = styled.input`
  height: 100%;
  width: 100%;
  border: 0;
  padding: 10px 12px;
  font-size: 11px;
  line-height: 19px;
  background: transparent;
  box-sizing: border-box;
  color: var(--main-color);
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: inherit;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--main-bg-color) inset !important;
  }
  ${mediaMin("desktopXLarge")} {
    margin-bottom: ${calculateResponsiveSize(20)};
    padding: ${calculateResponsiveSize(10)} ${calculateResponsiveSize(12)};
    font-size: ${calculateResponsiveSize(11)};
    line-height: ${calculateResponsiveSize(19)};
  }
`
export const ErrorMsg = styled.span`
  display: inline-block;
  margin-top: 14px;
  font-size: 11px;
  line-height: 147%;
  color: #ff6b6b;
  ${mediaMin("desktopXLarge")} {
    margin-top: ${calculateResponsiveSize(14)};
    font-size: ${calculateResponsiveSize(11)};
  }
`
export const CheckboxLabel = styled.label`
  display: flex;
  width: fit-content;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  span {
    display: flex;
    align-items: center;
    width: auto;
    font-size: 11px;
    line-height: 190%;
  }
  span::before {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    margin-right: 15px;
    border: 1px solid var(--main-color);
    transition: all 0.3s;
  }
  input[type="checkbox"] {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  input:checked ~ span:before {
    background: var(--main-color);
  }
  ${mediaMin("desktopXLarge")} {
    margin-bottom: ${calculateResponsiveSize(10)};
    span {
      font-size: ${calculateResponsiveSize(11)};
    }
    span::before {
      content: "";
      width: ${calculateResponsiveSize(10)};
      height: ${calculateResponsiveSize(10)};
      margin-right: ${calculateResponsiveSize(15)};
    }
  }
`

export const StyledSelect = styled(props => <Select {...props} />)`
  margin-bottom: 30px;
  max-width: 290px;
  &.is-invalid {
    border-color: #ff6b6b !important;
  }

  ${mediaMax("tabletLandscape")} {
    max-width: 100%;
  }
  
  ${mediaMax("phoneXL")} {
    max-width: 100%;
  }
  width: 100%;
  border: 1px solid var(--main-color) !important;
  box-sizing: border-box;
  &.react-dropdown-select {
    min-height: 34px;
    border-radius: 0;
    font-size: 11px;
    line-height: 190%;
    padding: 2px 10px;
    span {
      padding-top: 5px;
    }
  }
  &:focus,
  &:hover,
  &:focus-within {
    box-shadow: none !important;
    caret-color: transprent;
  }
  .react-dropdown-select-input {
    padding-top: 5px;
    cursor: pointer;
    caret-color: transparent;
    &::placeholder {
      font-size: 11px;
      color: inherit;
    }
  }
  .react-dropdown-select-dropdown {
    top: -1px;
    background-color: #efe8dc;
    font-size: 11px;
    line-height: 190%;
    caret-color: transparent;
  }
  .react-dropdown-select-item {
    padding: 5px 10px;
    border-bottom: 0;
    &:hover,
    &.react-dropdown-select-item-selected {
      background-color: var(--main-color);
      color: var(--primary-background-color);
    }
  }
  .react-dropdown-select-dropdown-handle {
    display: flex;
  }
  ${mediaMin("desktopXLarge")} {
    margin-bottom: ${calculateResponsiveSize(30)};
    max-width: ${calculateResponsiveSize(290)};

    &.react-dropdown-select {
      min-height: ${calculateResponsiveSize(34)};
      font-size: ${calculateResponsiveSize(11)};
      padding: ${calculateResponsiveSize(2)} ${calculateResponsiveSize(10)};
    }
    .react-dropdown-select-input {
      padding-top: ${calculateResponsiveSize(5)};
      &::placeholder {
        font-size: ${calculateResponsiveSize(11)};
      }
    }
    .react-dropdown-select-dropdown {
      font-size: ${calculateResponsiveSize(11)};
    }
    .react-dropdown-select-item {
      padding: ${calculateResponsiveSize(5)} ${calculateResponsiveSize(10)};
    }
  }
`
export const CheckBoxWrap = styled.div`
  &.is-invalid {
    ${CheckboxLabel} {
      span::before {
        border-color: #ff6b6b;
      }
    }
  }
`

export const Paragraph = styled.p`
  margin-bottom: 35px;
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  ${mediaMin("desktopXLarge")} {
    margin-bottom: ${calculateResponsiveSize(35)};
    font-size: ${calculateResponsiveSize(15)};
  }
`

export const Title = styled.h4`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #47341E;
  ${mediaMax("tabletLandscape")} {
    text-align: center;
  }
`

export const TitleDescription = styled.p`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  max-width: 300px;
  font-size: 13px;
  line-height: 175%;
  color: #47341E;
  ${mediaMax("tabletLandscape")} {
    text-align: center;
    max-width: 100%;
  }
`

export const LargeTitle = styled.h3`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #47341E;
`

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 32px;
  ${mediaMax("tabletLandscape")} {
    padding: 32px 16px;
  }
`

export const CheckboxButton = styled.div`
  display: flex;
  position: relative;
  // padding-left: 24px;
  // gap:10px;
  margin-bottom: 10px;
  // cursor: pointer;
  user-select: none;
  justify-content: center;
  width: 110px;
  align-items: flex-end;

  input {
  position: absolute;
  opacity: 0;
  // cursor: pointer;
  height: 0;
  width: 0;
  }

  label {
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index:1;
  }
`

export const Checkmark = styled.span`
  position: absolute;
  left: 2px;
  top: 2px;
  height: 10px;
  width: 10px;
  border: ${({ isError }) => isError ? '1px solid red' : '1px solid var(--main-color)'} ;
  transition: .2s linear;

  &:after {
  content: "";
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 6px;
  height: 10px;
  margin-top: -2px;
  border: 1px solid var(--main-color);
  filter: drop-shadow(0px 0px 10px var(--main-color));
  border-width: 0 2px 2px 0;
  transition: .2s linear;
  transform: scale(0.2);
  }

  ${CheckboxButton} input:checked ~ & {
  background-color: transparent;
  }

  ${CheckboxButton} input:checked ~ &:after {
  visibility: visible;
  opacity: 1;
  transform: rotate(0deg) scale(1);
  animation: pulse 1s ease-in;
  }

  ${CheckboxButton} input:checked ~ & {
  transform: rotate(45deg);
  border: none;
  }
`