import styled from "styled-components"
import { mediaMax, mediaMin } from "../../helpers/media-queries"
import { calculateResponsiveSize } from "../../helpers/calculate-responsive-size"

export const ThanksPageWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: calc(100vh - 290px);
  margin-top: 50px;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  ${mediaMax("tabletLandscape")} {
    flex-wrap: wrap-reverse;
    width: 100%;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-self: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  animation: pageFading 2s;
  ${mediaMax("tabletLandscape")} {
    height: 90vh;
    width: 100%;
    border-bottom: 1px solid #47341E;
    margin-bottom: 48px;
  }
`
export const Title = styled.h2`
  color: #386641;
  font-size: 60px;
  margin-bottom: 34px;
  line-height: 106%;
  font-weight: 400;
  text-transform: uppercase;
  ${mediaMax("tablet")} {
    font-size: 50px;
  }
  ${mediaMax("phoneXL")} {
    font-size: 35px;
    margin-bottom: 20px;
    br {
      display: none;
    }
  }
  ${mediaMin("desktopXLarge")} {
    font-size: ${calculateResponsiveSize(60)};
    margin-bottom: ${calculateResponsiveSize(34)};
  }
`
export const Description = styled.p`
  font-size: 17px;
  line-height: 175%;
  ${mediaMax("phoneXL")} {
    br {
      display: none;
    }
  }
  ${mediaMin("desktopXLarge")} {
    font-size: ${calculateResponsiveSize(17)};
  }
`

export const Paragraph = styled.p`
  margin-bottom: 35px;
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  ${mediaMin("desktopXLarge")} {
    margin-bottom: ${calculateResponsiveSize(35)};
    font-size: ${calculateResponsiveSize(15)};
  }
`

export const Title2 = styled.h4`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #47341E;
`

export const TitleDescription = styled.p`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  max-width: 300px;
  line-height: 175%;
  color: #47341E;
`

export const LargeTitle = styled.h3`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #47341E;
`

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 32px;
`
export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const OverwritedLink = styled.a`
  display: block;
`
