export const selcetRangedata = [
  { value: "$3500 - $3999", label: "$3500 - $3999" },
  { value: "$4000 - $4499", label: "$4000 - $4499" },
  { value: "$4500 - $4999", label: "$4500 - $4999" },
  { value: "$5000 - $5499", label: "$5000 - $5499" },
  { value: "$5500 - $5999", label: "$5500 - $5999" },
  { value: "$6000 - $6499", label: "$6000 - $6499" },
  { value: "$6500 - $6999", label: "$6500 - $6999" },
  { value: "$7000 - $7499", label: "$7000 - $7499" },
  { value: "$7500 - $7999", label: "$7500 - $7999" },
  { value: "$8000 - $8499", label: "$8000 - $8499" },
  { value: "$8500 - $8999", label: "$8500 - $8999" },
  { value: "$9000 - $9499", label: "$9000 - $9499" },
  { value: "$9500 - $9999", label: "$9500 - $9999" },
  { value: "$10, 000 +", label: "$10, 000 +" },
]