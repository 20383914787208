import React from "react"
import { parseIncompletePhoneNumber, parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js"

import { postcodeValidator } from "postcode-validator"
import {
  RegisterWrap,
  Col,
  FieldWrappper,
  Input,
  ErrorMsg,
  CheckboxLabel,
  StyledSelect,
  CheckBoxWrap,
  LinkWrapper,
  CheckBoxGroupWrapper,
  InputsWraper,
  CheckboxButton,
  Checkmark,
  CheckboxLayoutWrapper
} from "./index.style"
import { Button, Title } from "../ui"
import { useForm } from "react-hook-form"
import { DropdownArrow } from "../../icons/dropdown-arrow"
import { RegisterDropdownFirst, RegisterDropdownSecond } from "../../data"
import { navigate } from "gatsby"
import submitForm from "../../helpers/submit-form"
import InputMask from "react-input-mask"
import { selcetRangedata } from "../../data/selcetRangeData"
import CategoryLink from "../categories/category-link"
import LeasingGalery from "./leasing-gallery"
import useDeviceDetect from "../../hooks/use-device-detect"
import { countriesData } from "./countriesData"

const Register = () => {
  const { isMobile } = useDeviceDetect()

  const [selectedCountry, setSelectedCountry] = React.useState("");

  const sortedCountries = countriesData
    .filter((country) => country.value !== "INTL")
    .sort((a, b) => a.label.localeCompare(b.label));

  const sortedCountriesWithOther = [
    ...sortedCountries,
    countriesData.find((country) => country.value === "INTL"),
  ];

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    // clearErrors,
    getValues,
    formState: { errors }
  } = useForm()

  const validateCheckbox = v => {
    const values = getValues()

    const isError = !!(
      values.studio ||
      values.oneBedroom ||
      values.twoBedroom ||
      values.threeBedroom
    )

    return isError
  }

  const validatePhone = v => {
    const value = getValues()
    const country = selectedCountry === "INTL" ? "" : selectedCountry || "US"
    const phoneNumber = parseIncompletePhoneNumber("+" + value.phone)
  
    if (!isValidPhoneNumber(phoneNumber)) {
      return false
    }
  
    if (country === "" || country === parsePhoneNumber(phoneNumber).country) {
      return true
    }
  
    return false
  }

  const validateZipcode = v => {
    const value = getValues()
    return postcodeValidator(value.zipCode, selectedCountry || "US")
  }

  const onSubmit = data => {
    const checkbox = [
      data.studio,
      data.oneBedroom,
      data.twoBedroom,
      data.threeBedroom
    ].filter(v => v)

    submitForm({
      FirstName: data.firstName,
      LastName: data.lastName,
      Email: data.email,
      Country: data.country,
      Phone: data.phone,
      ZipCode: data.zipCode,
      ResidenceType: checkbox,
      HowHear: data.dropdownOne,
      WhenMove: data.dropdownTwo,
      Range: data.selectRange
    })

    navigate("/thanks")
  }

  const handleChangeSelect = (name = "", event) => {
    const firstValue = event?.[0]?.value
    if (firstValue) {
      if (name === "country") {
        setSelectedCountry(firstValue)
      }
      setValue(name, firstValue, { shouldValidate: true })
    } else {
      setError(name, { type: "custom", message: "Required field" })
    }
  }

  React.useEffect(() => {
    register("country", { required: true })
    register("dropdownOne", { required: true })
    register("dropdownTwo", { required: true })
    register("selectRange", { required: true })
  }, [])

  return (
    <>
      <RegisterWrap onSubmit={handleSubmit(onSubmit)}>
        <Col>{!isMobile ? <LeasingGalery /> : null}</Col>
        <InputsWraper>
          <Col>
            <Title>A little bit about you</Title>
            <FieldWrappper className={errors.firstName ? "is-invalid" : ""}>
              <Input
                type="text"
                defaultValue=""
                minLength="1"
                placeholder="First Name"
                name="firstName"
                {...register("firstName", { required: true })}
              />
            </FieldWrappper>
            <FieldWrappper className={errors.lastName ? "is-invalid" : ""}>
              <Input
                type="text"
                minLength="1"
                defaultValue=""
                placeholder="Last Name"
                name="lastName"
                {...register("lastName", { required: true })}
              />
            </FieldWrappper>
            <FieldWrappper className={errors.email ? "is-invalid" : ""}>
              <Input
                type="email"
                defaultValue=""
                placeholder="Email"
                name="email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/
                  }
                })}
              />
            </FieldWrappper>
            <StyledSelect
              className={errors.country ? "is-invalid" : ""}
              name="country"
              placeholder="Country"
              options={sortedCountriesWithOther}
              onChange={handleChangeSelect.bind(null, "country")}
              value={selectedCountry}
              searchable={true}
              isClearable={true}
              dropdownHandleRenderer={() => <DropdownArrow />}
              dropdownPosition="bottom"
            />
            <FieldWrappper className={errors.phone ? "is-invalid" : ""}>
              <Input
                type="text"
                as={InputMask}
                mask="+999999999999999"
                maskChar=" "
                defaultValue=""
                placeholder="Phone"
                name="phone"
                {...register(
                  "phone",

                  {
                    validate: validatePhone
                  }
                )}
              />
            </FieldWrappper>
            <FieldWrappper className={errors.zipCode ? "is-invalid" : ""}>
              <Input
                type="text"
                // as={InputMask}
                // mask="99999"
                defaultValue=""
                placeholder="ZIP Code"
                name="zipCode"
                {...register("zipCode", {
                  validate: validateZipcode
                })}
              />
            </FieldWrappper>
            <CheckBoxGroupWrapper>
              <CheckBoxWrap
                className={
                  errors.studio &&
                  errors.oneBedroom &&
                  errors.twoBedroom &&
                  errors.threeBedroom
                    ? "is-invalid"
                    : ""
                }
              >
                <Title>Residence Type</Title>
                <CheckboxLayoutWrapper>
                  <CheckboxButton>
                    <label
                      style={{
                        marginLeft: "-22px"
                      }}
                      htmlFor="checkbox"
                    >
                      {" "}
                      Studio
                    </label>
                    <input
                      id="checkbox"
                      value="studios"
                      name="bedrooms"
                      type="checkbox"
                      {...register("studio", { validate: validateCheckbox })}
                    />
                    <Checkmark
                      isError={
                        errors.studio &&
                        errors.oneBedroom &&
                        errors.twoBedroom &&
                        errors.threeBedroom
                      }
                    />
                  </CheckboxButton>
                  <CheckboxButton>
                    <label
                      style={{
                        marginLeft: "-2px"
                      }}
                      htmlFor="checkbox-2"
                    >
                      1 Bedroom
                    </label>
                    <input
                      id="checkbox-2"
                      value="1 Bedroom"
                      name="bedrooms"
                      type="checkbox"
                      {...register("oneBedroom", {
                        validate: validateCheckbox
                      })}
                    />
                    <Checkmark
                      isError={
                        errors.studio &&
                        errors.oneBedroom &&
                        errors.twoBedroom &&
                        errors.threeBedroom
                      }
                    />
                  </CheckboxButton>
                  <CheckboxButton>
                    <label htmlFor="checkbox-3">2 Bedrooms</label>
                    <input
                      id="checkbox-3"
                      value="2 Bedroom"
                      name="bedrooms"
                      type="checkbox"
                      {...register("twoBedroom", {
                        validate: validateCheckbox
                      })}
                    />
                    <Checkmark
                      isError={
                        errors.studio &&
                        errors.oneBedroom &&
                        errors.twoBedroom &&
                        errors.threeBedroom
                      }
                    />
                  </CheckboxButton>
                  <CheckboxButton>
                    <label htmlFor="checkbox-4">3 Bedrooms</label>
                    <input
                      id="checkbox-4"
                      value="3 Bedroom"
                      name="bedrooms"
                      type="checkbox"
                      {...register("threeBedroom", {
                        validate: validateCheckbox
                      })}
                    />
                    <Checkmark
                      isError={
                        errors.studio &&
                        errors.oneBedroom &&
                        errors.twoBedroom &&
                        errors.threeBedroom
                      }
                    />
                  </CheckboxButton>
                </CheckboxLayoutWrapper>
              </CheckBoxWrap>
            </CheckBoxGroupWrapper>
          </Col>
          <Col>
            <Title>WHAT IS YOUR PRICE RANGE?</Title>
            <StyledSelect
              className={errors.selectRange ? "is-invalid" : ""}
              name="selectRange"
              searchable={false}
              dropdownHandleRenderer={() => <DropdownArrow />}
              options={selcetRangedata}
              dropdownPosition="bottom"
              onChange={handleChangeSelect.bind(null, "selectRange")}
            />
            <Title>HOW DID YOU HEAR ABOUT US?</Title>
            <StyledSelect
              className={errors.dropdownOne ? "is-invalid" : ""}
              name="dropdownOne"
              searchable={false}
              dropdownHandleRenderer={() => <DropdownArrow />}
              options={RegisterDropdownFirst}
              dropdownPosition="bottom"
              onChange={handleChangeSelect.bind(null, "dropdownOne")}
            />
            <Title>When are you looking to move?</Title>
            <StyledSelect
              className={errors.dropdownTwo ? "is-invalid" : ""}
              name="dropdownTwo"
              searchable={false}
              dropdownHandleRenderer={() => <DropdownArrow />}
              options={RegisterDropdownSecond}
              dropdownPosition="bottom"
              onChange={handleChangeSelect.bind(null, "dropdownTwo")}
            />

            <Button>SEND</Button>

            {errors.firstName ||
            errors.lastName ||
            errors.email ||
            errors.phone ||
            errors.zipCode ||
            (errors.studio &&
            errors.oneBedroom &&
            errors.twoBedroom &&
            errors.threeBedroom
              ? true
              : false) ||
            errors.dropdownOne ||
            errors.dropdownTwo ? (
              <ErrorMsg>
                Please complete all mandatory fields to proceed.
              </ErrorMsg>
            ) : null}
          </Col>
        </InputsWraper>
        {isMobile ? <LeasingGalery /> : null}
      </RegisterWrap>
      <LinkWrapper>
        <CategoryLink title={"TEAM"} link={"/team"} />
      </LinkWrapper>
    </>
  )
}

export default Register
